import { sortBy } from '@st/utils'
import type {
  TSportEvent,
  CustomMenuClass,
  CustomMenuItem,
  SbTreeEntityType,
} from '../types'
import { EVENT_ALLOWED_STATES } from '../constants'

export type EventsByEntityType = Record<string, Set<number>>
type GetEventById = (id: number) => TSportEvent

function updateEventsByEntity(
  customMenu: CustomMenuClass,
  event: TSportEvent,
  eventsByEntity: EventsByEntityType,
  entity?: CustomMenuItem,
) {
  if (!entity) return
  if (!EVENT_ALLOWED_STATES.includes(event.state)) return
  const { parentId, entityId } = entity

  if (!eventsByEntity[entityId]) eventsByEntity[entityId] = new Set()
  eventsByEntity[entityId].add(event.id)

  if (parentId) {
    const [parentType, parentOriginalId] = parentId.split('-')
    const parent = customMenu.findById(
      parentType as SbTreeEntityType,
      Number(parentOriginalId),
    )

    updateEventsByEntity(customMenu, event, eventsByEntity, parent)
  }
}

export function buildEventsByEntityMap(
  customMenu: CustomMenuClass,
  events: TSportEvent[],
) {
  const eventsByEntity: EventsByEntityType = {}

  events.forEach((event) => {
    const tournament = customMenu.findById('tournament', event.tournament_id)
    updateEventsByEntity(customMenu, event, eventsByEntity, tournament)
  })

  return eventsByEntity
}

export function filterCustomMenuByMap(
  customMenu: CustomMenuClass,
  eventsByEntity: EventsByEntityType,
) {
  return customMenu.menu.filter(
    (entity) => eventsByEntity[entity.entityId]?.size,
  )
}

export function getCustomSports(
  customMenu: CustomMenuClass,
  events: TSportEvent[],
) {
  return filterCustomMenuByMap(
    customMenu,
    buildEventsByEntityMap(customMenu, events),
  )
}

export function getTournamentsListFromNode(
  node: CustomMenuItem,
): CustomMenuItem[] {
  if (!node.children?.length) return [node]

  return node.children.flatMap((childNode) =>
    getTournamentsListFromNode(childNode),
  )
}

function getEventsListFromNode(
  node: CustomMenuItem,
  eventsByEntity: EventsByEntityType,
  getEventById: GetEventById,
): TSportEvent[] {
  if (!node.children?.length) {
    const eventIds = Array.from(eventsByEntity?.[node.entityId] || [])
    const events = eventIds.map((id) => getEventById(id))
    sortBy(events, (event) => [event.scheduled])
    return events
  } else {
    return node.children.flatMap((childNode) =>
      getEventsListFromNode(childNode, eventsByEntity, getEventById),
    )
  }
}

/**
 * Сортирует события как в customMenu
 * @example
 * getEventsList(MS.customMenu, MS.sportEvents.live, MS.sportEvents.getById)
 */
export function getEventsList(
  customMenu: CustomMenuClass,
  events: TSportEvent[],
  getEventById: GetEventById,
) {
  const eventsByEntity = buildEventsByEntityMap(customMenu, events)

  return customMenu.menu.flatMap((node) =>
    getEventsListFromNode(node, eventsByEntity, getEventById),
  )
}
